import React from 'react'
import { useCaseContext } from '../../../Contexts'
import {
  Box,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch
} from '@mui/material'
import './statusSwitch.sass'

export interface Props {
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  isChecked?: boolean
  label?: string
  width?: string
}

export const StatusSwitch = ({
  onChange,
  isChecked = false,
  label = '',
  width = '50%'
}: Props) => {
  const { isLoading: isUpdatingCase } = useCaseContext()

  return (
    <div className='status-switch' style={{ width: width }}>
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={isChecked} onChange={onChange} />}
          label={label}
          labelPlacement='end'
        />
      </FormGroup>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 38,
          width: 38
        }}
      >
        {isUpdatingCase && (
          <CircularProgress className='status-switch__circular-progress' />
        )}
      </Box>
    </div>
  )
}
