import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import { Box, Tooltip } from '@mui/material'
import React from 'react'

export const organizationNameColumnId = 'organizationName'

export const useOrganizationNameColumnHeader =
  (): MRT_ColumnDef<FailuresListEntry> => {
    return {
      id: organizationNameColumnId,
      header: 'Organization',
      accessorKey: 'organizationName',
      enableColumnDragging: false,
      enableColumnActions: false,
      enableColumnFilterModes: false,
      enableResizing: true,
      filterVariant: 'text',
      minSize: 100,
      maxSize: 200,
      aggregationFn: 'unique',
      Cell: ({ row }: { row: MRT_Row<FailuresListEntry> }) => {
        const organizationName = row.original.organizationName
        return (
          <Box>
            <Tooltip title={organizationName}>
              <Box>{organizationName}</Box>
            </Tooltip>
          </Box>
        )
      }
    }
  }
