import { FailuresListEntry } from '../models/failuresListEntry'
import { Row } from '@tanstack/table-core/src/types'

const alarmStateSortOrder = ['ALARM', 'WARN', 'NORM', 'OKAY', 'N/A']

export const sortingFailureState = (
  rowA: Row<FailuresListEntry>,
  rowB: Row<FailuresListEntry>,
  columnId: string
): number => {
  const cellValueA = rowA.getValue<string>(columnId)
  const cellValueB = rowB.getValue<string>(columnId)

  const stateA = cellValueA?.replaceAll('FAILURESTATE_', '') ?? ''
  const stateB = cellValueB?.replaceAll('FAILURESTATE_', '') ?? ''

  return (
    alarmStateSortOrder.indexOf(stateA) - alarmStateSortOrder.indexOf(stateB)
  )
}
