import { MRT_ColumnDef } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'

export const pumpIdColumnId = 'pumpId'

export const usePumpIdColumnHeader = (): MRT_ColumnDef<FailuresListEntry> => {
  return {
    id: pumpIdColumnId,
    header: 'Pump ID',
    accessorKey: 'pumpId',
    enableGlobalFilter: true,
    visibleInShowHideMenu: false
  }
}
