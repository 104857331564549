import React, { memo, useCallback, useMemo } from 'react'
import { AppBar, Button, Toolbar, Typography } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import { ReactComponent as Logo } from './ksb-logo-834104de.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { BackButton } from '../BackButton'
import IconButton from '@mui/material/IconButton'
import { TableView } from '@mui/icons-material'

export const TopBar = memo(({ signOut }: { signOut: Function }) => {
  const handleClick = useCallback(() => {
    signOut()
  }, [signOut])
  const navigate = useNavigate()
  const location = useLocation()

  const handleChangeTableView = useCallback(() => {
    if (location.pathname === '/failures-list-view') {
      navigate('/')
    } else {
      navigate('/failures-list-view')
    }
  }, [location.pathname, navigate])

  const handleLogoClick = useCallback(() => {
    navigate('/')
  }, [navigate])

  return useMemo(
    () => (
      <AppBar position='static'>
        <Toolbar>
          <Typography
            variant='h5'
            component='div'
            className='menu-title'
            sx={{ flexGrow: 1 }}
          >
            <Logo
              style={{
                width: 50,
                height: 50,
                cursor: 'pointer',
                marginTop: '-7px',
                marginRight: 20
              }}
              onClick={handleLogoClick}
            />
            <span style={{ cursor: 'pointer' }} onClick={handleLogoClick}>
              Issue Management
            </span>
            <IconButton
              data-testid='change-table-view-button'
              onClick={handleChangeTableView}
              style={{
                cursor: 'pointer',
                color: '#00569d'
              }}
            >
              <TableView />
            </IconButton>
          </Typography>
          <Button
            sx={{ color: '#00569d' }}
            onClick={handleClick}
            data-testid='logout-button'
          >
            <LogoutIcon />
          </Button>
        </Toolbar>
      </AppBar>
    ),
    [handleChangeTableView, handleClick, handleLogoClick]
  )
})
