import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import { Box, Tooltip } from '@mui/material'
import React from 'react'

export const pumpNameColumnId = 'pumpName'

export const usePumpNameColumnHeader = (): MRT_ColumnDef<FailuresListEntry> => {
  return {
    id: pumpNameColumnId,
    header: 'Pump',
    accessorKey: 'pumpName',
    enableColumnDragging: false,
    enableColumnActions: false,
    enableGrouping: true,
    enableColumnFilterModes: false,
    enableResizing: true,
    minSize: 100,
    maxSize: 200,
    Cell: ({ row }: { row: MRT_Row<FailuresListEntry> }) => {
      const pumpName = row.original.pumpName
      return (
        <Box>
          <Tooltip title={pumpName}>
            <Box>{pumpName}</Box>
          </Tooltip>
        </Box>
      )
    }
  }
}
