import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

import { useQuery } from 'react-query'
import { Config, useConfig } from '../hooks/useConfig'
import { useAuth } from './AuthContext'
import { CaseListEntry, FailureTypes } from './types'
import { filterOutCasesByAcknowledgements } from './filters/filterOutCasesByAcknowledgements/filterOutCasesByAcknowledgements'

type CasesContextType = {
  filteredCases: CaseListEntry[]
  handleSearch: (searchTerm: string) => void
  showClosedCases: boolean
  showMutedCases: boolean
  setShowClosedCases: React.Dispatch<React.SetStateAction<boolean>>
  setShowMutedCases: React.Dispatch<React.SetStateAction<boolean>>
  searchTerm: string
  highlightTerm: (text: string) => (JSX.Element | string)[] | string
  acknowledgements: string[]
  setAcknowledgements: React.Dispatch<React.SetStateAction<string[]>>
}

const CasesContext = createContext<CasesContextType | undefined>(undefined)

type CasesProviderProps = {
  children: ReactNode
  config: Config
}

export const CasesProvider: React.FC<CasesProviderProps> = ({
  children,
  config
}) => {
  const [showClosedCases, setShowClosedCases] = useState<boolean>(false)
  const [showMutedCases, setShowMutedCases] = useState<boolean>(false)
  const [acknowledgements, setAcknowledgements] = useState<string[]>([
    FailureTypes.VIBRATION
  ])

  const [searchTerm, setSearchTerm] = useState('')

  const { casesData } = useFetchCases()

  const cases = useMemo(() => casesData?.cases || [], [casesData?.cases])

  for (const caseId in cases) {
    const currentCase = cases[caseId]
    const tags = currentCase.tags
    currentCase.muted = tags?.length > 0 ? tags.includes('muted') : false
  }

  const getFilteredCases = useCallback(() => {
    if (!casesData?.cases) {
      return []
    }

    const splitSearchTerms = searchTerm.split(' ').filter((item) => item !== '')
    let filteredCases = casesData?.cases?.length > 0 ? cases : []

    if (!showClosedCases) {
      filteredCases = filteredCases.filter(
        (pump: CaseListEntry) => pump.status !== 'CLOSED'
      )
    }
    if (!showMutedCases) {
      filteredCases = filteredCases.filter((pump: CaseListEntry) => !pump.muted)
    }

    if (acknowledgements.length > 0) {
      filteredCases = filterOutCasesByAcknowledgements(
        filteredCases,
        acknowledgements
      )
    }

    for (const term of splitSearchTerms) {
      filteredCases = filteredCases.filter((caseListEntry: CaseListEntry) => {
        const matchesSearch =
          matchesTermCaseInsensitive(caseListEntry.pumpName, term) ||
          matchesTermCaseInsensitive(caseListEntry.pumpLocation, term) ||
          matchesTermCaseInsensitive(
            caseListEntry.pumpTechnicalLocation,
            term
          ) ||
          matchesTermCaseInsensitive(caseListEntry.pumpSerialNumber, term) ||
          matchesTermCaseInsensitive(caseListEntry.pumpTypeSeries, term) ||
          matchesTermCaseInsensitive(caseListEntry.organizationName, term) ||
          matchesTermCaseInsensitive(caseListEntry.pumpManufacturer, term) ||
          matchesTermCaseInsensitive(
            caseListEntry.pumpSensorsSerialNumber_0,
            term
          ) ||
          matchesTermCaseInsensitive(caseListEntry.assignee, term) ||
          caseListEntry.tags.some((tag) =>
            matchesTermCaseInsensitive(tag, term)
          )

        return matchesSearch
      })
    }
    return filteredCases
  }, [
    casesData?.cases,
    cases,
    searchTerm,
    showClosedCases,
    showMutedCases,
    acknowledgements
  ])

  const matchesTermCaseInsensitive = (
    textToMatch: string,
    searchTerm: string
  ): boolean => {
    return (
      textToMatch !== undefined &&
      textToMatch.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }

  const handleSearch = (term: string): void => {
    setSearchTerm(term.toLowerCase())
  }

  const escapeRegex = useCallback((str: string) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }, [])

  const highlightTerm = useCallback(
    (text: string): (JSX.Element | string)[] | string => {
      if (!searchTerm.trim()) return text

      const terms = searchTerm.trim().split(/\s+/).map(escapeRegex)
      const regex = new RegExp(
        `(${terms.map((t) => t.trim()).join('|')})`,
        'gi'
      )
      const parts = text.split(regex)

      return parts.map((part, index) =>
        terms.some((t) => new RegExp(`^${t.trim()}$`, 'gi').test(part)) ? (
          <span key={index} className='highlighted'>
            {part}
          </span>
        ) : (
          part
        )
      )
    },
    [searchTerm, escapeRegex]
  )

  const filteredCases = getFilteredCases()

  return (
    <CasesContext.Provider
      value={{
        filteredCases,
        handleSearch,
        showClosedCases,
        showMutedCases,
        setShowClosedCases,
        setShowMutedCases,
        searchTerm,
        highlightTerm,
        acknowledgements,
        setAcknowledgements
      }}
    >
      {children}
    </CasesContext.Provider>
  )
}

export const useCasesContext = () => {
  const context = useContext(CasesContext)
  if (!context) {
    throw new Error(
      'useCasesContext must be used within a CasesContextProvider'
    )
  }
  return context
}

export const useFetchCases = () => {
  const auth = useAuth()
  const config = useConfig()
  const CASES_URL = config?.API_HOST_URL + '/cases'

  const { data, isLoading, isError, refetch } = useQuery<{
    cases: CaseListEntry[]
  }>(
    'cases',
    async () => {
      const response = await fetch(CASES_URL, {
        headers: {
          Authorization: await auth!.getBearer()
        }
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    },
    {
      enabled: !!config,
      refetchOnWindowFocus: false
    }
  )

  return { casesData: data, isLoading, isError, refetchCases: refetch }
}
