import React, { FC, useState } from 'react'
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { Failure } from '../../../../../../Contexts/types'
import { StatusSwitch } from '../../../../../PumpInfoPanel/StatusSwitch/statusSwitch'
import { ConfirmationDialog } from '../../../../../../ConfirmationDialog'
import { useCaseContext } from '../../../../../../Contexts'
import { SnackbarError } from '../../../../../snackbarError'
import { formatDateToUtcString } from '../../../../../../utils/formatDateString'
import { formatFailureType } from '../../../../../../utils/formatFailureType'

dayjs.extend(utc)

export interface MutingSwitchProps {
  caseId: string
  failure: Failure
}

export const MutingSwitch: FC<MutingSwitchProps> = (props) => {
  const { muteFailure, unmuteFailure } = useCaseContext()

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [showDatepicker, setShowDatepicker] = useState(false)
  const [activeToggleButton, setActiveToggleButton] = useState('1day')
  const [muteUntil, setMuteUntil] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  let isMuted = false
  if (isFailureMuted(props.failure.mutedUntil)) {
    isMuted = true
  }

  const toggleButtonOnChange = (
    event: React.MouseEvent<HTMLElement> | null,
    newValue: string | null
  ) => {
    if (newValue !== null) {
      setActiveToggleButton(newValue)

      if (newValue === '1day') {
        setMuteUntil(buildMutedUntilWithDaysOffset(1))
        setShowDatepicker(false)
      }
      if (newValue === '3days') {
        setMuteUntil(buildMutedUntilWithDaysOffset(3))
        setShowDatepicker(false)
      }
      if (newValue === '1week') {
        setMuteUntil(buildMutedUntilWithDaysOffset(7))
        setShowDatepicker(false)
      }
      if (newValue === 'custom') {
        setShowDatepicker(true)
      }
    }
  }

  const datepickerOnChange = (newValue: Dayjs | null) => {
    setShowDatepicker(false)
    setMuteUntil(dayjs(newValue).toISOString())
    toggleButtonOnChange(null, '')
  }

  const muteStatusSwitchOnChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setMuteUntil(buildMutedUntilWithDaysOffset(1))
      setActiveToggleButton('1day')
      setIsDialogOpen(true)
      setShowDatepicker(false)
    } else {
      try {
        await unmuteFailure(props.failure.failureType)
      } catch (error) {
        setErrorMessage('Error occurred while unmuting failure')
      }
    }
  }

  const muteOnConfirm = async (): Promise<void> => {
    try {
      await muteFailure(props.failure.failureType, muteUntil)
    } catch (error) {
      setErrorMessage('Error occurred while muting failure')
    } finally {
      setIsDialogOpen(false)
    }
  }

  return (
    <>
      {errorMessage && (
        <SnackbarError
          message={errorMessage}
          onClose={() => setErrorMessage('')}
        />
      )}
      <ConfirmationDialog
        title={`Muting ${formatFailureType(props.failure.failureType)}`}
        onCancel={() => setIsDialogOpen(false)}
        isOpen={isDialogOpen}
        onConfirm={muteOnConfirm}
        confirmLabel={'Mute'}
      >
        <ToggleButtonGroup
          value={activeToggleButton}
          exclusive
          onChange={toggleButtonOnChange}
          sx={{ justifyContent: 'center', padding: '0 20px 10px' }}
        >
          <ToggleButton value='1day'>1 day</ToggleButton>
          <ToggleButton value='3days'>3 days</ToggleButton>
          <ToggleButton value='1week'>1 week</ToggleButton>
          <ToggleButton value='custom'>choose date</ToggleButton>
        </ToggleButtonGroup>
        {showDatepicker ? (
          <StaticDatePicker
            timezone={'UTC'}
            shouldDisableDate={datepickerDisablePastAndToday}
            onChange={datepickerOnChange}
            views={['day']}
            slotProps={{
              toolbar: {
                hidden: true
              },
              actionBar: {
                actions: []
              }
            }}
          />
        ) : (
          <Grid container sx={{ padding: '20px', minWidth: '600px' }}>
            <Grid item xs={6} sx={{ color: 'gray' }}>
              {formatFailureType(props.failure.failureType)} will be muted until{' '}
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}
            >
              {formatDateToUtcString(new Date(muteUntil), {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                weekday: 'long',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
              })}
            </Grid>
          </Grid>
        )}
      </ConfirmationDialog>
      <Grid
        container
        data-testid={`${props.failure.failureType}-muting-toggle`}
      >
        <StatusSwitch
          label={
            isMuted
              ? `Muted until ${
                  props.failure.mutedUntil &&
                  formatDateToUtcString(new Date(props.failure.mutedUntil), {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    weekday: 'long'
                  })
                }`
              : 'Muted'
          }
          onChange={muteStatusSwitchOnChange}
          isChecked={isMuted}
          width={'100%'}
        />
      </Grid>
    </>
  )
}

function buildMutedUntilWithDaysOffset(daysOffset: number): string {
  return dayjs().utc().add(daysOffset, 'day').startOf('day').toISOString()
}

function datepickerDisablePastAndToday(date: Dayjs): boolean {
  if (dayjs(date).isSame(dayjs(), 'day')) {
    return true
  }

  return dayjs(date).isBefore(dayjs(), 'day')
}

function isFailureMuted(mutedUntil: string | undefined): boolean {
  if (!mutedUntil) {
    return false
  }

  const mutedUntilTimestamp = new Date(mutedUntil).getTime()

  return mutedUntilTimestamp > Date.now()
}
