import {
  MRT_ColumnFiltersState,
  MRT_ColumnOrderState,
  MRT_ColumnPinningState,
  MRT_DensityState,
  MRT_ExpandedState,
  MRT_GroupingState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_VisibilityState
} from 'material-react-table'
import { guardSensorIdColumnId } from '../../columnHeaders/definitions/useGuardSensorIdColumnHeader'
import { pumpIdColumnId } from '../../columnHeaders/definitions/usePumpIdColumnHeader'
import { acknowledgedColumFilterInitialState } from './acknowledgedColumFilterInitialState'
import { lastOccurrenceSortInitialState } from './lastOccurrenceSortInitialState'
import { mutedColumFilterInitialState } from './mutedColumFilterInitialState'

interface IInitialState {
  columnOrder: MRT_ColumnOrderState
  columnFilters: MRT_ColumnFiltersState
  columnVisibility: MRT_VisibilityState
  density: MRT_DensityState
  globalFilter: string | undefined
  showGlobalFilter: boolean
  showColumnFilters: boolean
  sorting: MRT_SortingState
  grouping: MRT_GroupingState
  pagination: MRT_PaginationState
  expanded: MRT_ExpandedState
  columnPinning: MRT_ColumnPinningState
  hideSpecificTags: boolean
}

export const getInitialState = (): IInitialState => {
  return {
    columnOrder: [
      'countryCode',
      'organizationName',
      'pumpName',
      'location',
      'failureType',
      'currentState',
      'highestState',
      'assignee',
      'firstOccurrence',
      'lastOccurrence',
      'tags',
      'externalLink',
      'isMuted',
      'isAcknowledged',
      'guardSensorId',
      'pumpId'
    ],
    columnFilters: [
      acknowledgedColumFilterInitialState,
      mutedColumFilterInitialState
    ],
    columnVisibility: {
      [guardSensorIdColumnId]: false,
      [pumpIdColumnId]: false
    },
    density: 'compact',
    globalFilter: undefined,
    showGlobalFilter: true,
    showColumnFilters: true,
    sorting: [lastOccurrenceSortInitialState],
    grouping: [],
    pagination: {
      pageIndex: 0,
      pageSize: 25
    },
    expanded: { 0: false },
    columnPinning: {},
    hideSpecificTags: true
  }
}
