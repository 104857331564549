import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import React from 'react'
import { FailuresListEntry } from '../../models/failuresListEntry'
import { Box } from '@mui/material'

export const lastOccurrenceColumnId = 'lastOccurrence'

export const useLastOccurrenceColumnHeader =
  (): MRT_ColumnDef<FailuresListEntry> => {
    return {
      id: lastOccurrenceColumnId,
      header: 'Last Occurrence',
      accessorKey: 'lastOccurrence',
      enableColumnDragging: false,
      enableColumnActions: false,
      enableColumnFilterModes: false,
      enableResizing: false,
      filterVariant: 'date-range',
      enableClickToCopy: false,
      minSize: 100,
      maxSize: 100,
      accessorFn: (row) => row.lastOccurrence,
      Cell: ({ row }: { row: MRT_Row<FailuresListEntry> }) => {
        const lastOccurrence = row.original.lastOccurrence
        return (
          <Box>
            <Box>
              {lastOccurrence.toLocaleDateString('en-GB', {
                timeZone: 'UTC',
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              })}
            </Box>
            <Box>
              {lastOccurrence.toLocaleTimeString('en-GB', {
                timeZone: 'UTC'
              })}
            </Box>
          </Box>
        )
      },
    }
  }
