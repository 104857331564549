import React, { useMemo } from 'react'
import { FailuresList } from './failuresList'
import {
  FailureListExternalLink,
  FailureListTag,
  FailureListTagType,
  FailuresListEntry
} from './models/failuresListEntry'
import { useFetchCases } from '../Contexts/CasesContext'
import { CaseListEntry, Failure } from '../Contexts/types'
import { generateLinkToMDC } from '../utils/generateLinkToMDC'

const mapCaseListEntriesToFailuresListEntries = (
  caseListEntries: CaseListEntry[]
): FailuresListEntry[] => {
  const entries: FailuresListEntry[] = []

  caseListEntries.forEach((caseListEntry) => {
    const failuresListEntries =
      mapCaseListEntryToFailuresListEntries(caseListEntry)
    entries.push(...failuresListEntries)
  })

  return entries
}

const mapCaseListEntryToFailuresListEntries = (
  caseListEntry: CaseListEntry
): FailuresListEntry[] => {
  const createExternalLinks = (failure: Failure): FailureListExternalLink[] => {
    const createAdminPortalLink = (): FailureListExternalLink => ({
      url: `https://admin.ksbguard.net/pump-detail/${caseListEntry.pumpId}`,
      label: 'Admin Portal'
    })

    const createMDCLink = (): FailureListExternalLink | null =>
      caseListEntry.organizationId && caseListEntry.pumpId
        ? {
            url: generateLinkToMDC(
              caseListEntry.organizationId,
              caseListEntry.pumpId,
              failure.firstOccurrence,
              failure.lastOccurrence
            ),
            label: 'MD Cockpit'
          }
        : null

    const links: FailureListExternalLink[] = [createAdminPortalLink()]
    const mdcLink = createMDCLink()
    if (mdcLink) {
      links.push(mdcLink)
    }

    return links.sort((a, b) => a.label.localeCompare(b.label))
  }

  const mapTags = (): FailureListTag[] => [
    ...caseListEntry.tags.map((tag) => ({
      value: tag,
      type: FailureListTagType.user
    })),
    ...caseListEntry.guardTags.map((guardTag) => ({
      value: guardTag,
      type: FailureListTagType.guard
    }))
  ]

  return caseListEntry.failures.map((failure) => ({
    externalLinks: createExternalLinks(failure),
    countryCode: caseListEntry.pumpCountryCode,
    organizationName: caseListEntry.organizationName,
    failureType: failure.failureType,
    currentState: failure.failureState,
    highestState: undefined, // Set explicitly if required
    counterSinceLastAcknowledgement: 0, // Assuming a default value
    firstOccurrence: new Date(failure.firstOccurrence),
    lastOccurrence: new Date(failure.lastOccurrence),
    pumpName: caseListEntry.pumpName,
    pumpId: caseListEntry.id,
    location: {
      location: caseListEntry.pumpLocation,
      technicalLocation: caseListEntry.pumpTechnicalLocation
    },
    assignee: caseListEntry.assignee,
    isAcknowledged: failure.acknowledged,
    mutedUntil: failure.mutedUntil,
    guardSensorId: caseListEntry.pumpSensorsSerialNumber_0,
    tags: mapTags()
  }))
}

interface IFailureListContainerProps {}

export const FailureListContainer: React.FC<
  IFailureListContainerProps
> = () => {
  const { isLoading, refetchCases, casesData } = useFetchCases()
  const cases = useMemo(() => casesData?.cases || [], [casesData?.cases])

  const data = useMemo<FailuresListEntry[]>(() => {
    return mapCaseListEntriesToFailuresListEntries(cases)
  }, [cases])

  const refresh = async () => {
    await refetchCases()
  }

  return (
    <div>
      <FailuresList data={data} refreshAction={refresh} isLoading={isLoading} />
    </div>
  )
}
