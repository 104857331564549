import { localStorageKeys } from './localStorageKeys'
import { ISetFailuresStateActions } from './ISetFailuresStateActions'
import { getInitialState } from './initialState/getInitialState'

export const resetStateFromLocalStorage = (
  setActions: ISetFailuresStateActions,
  afterReset?: () => void
) => {
  localStorageKeys.forEach((key) => localStorage.removeItem(key))

  const initialState = getInitialState()
  setActions.setColumnOrder(initialState.columnOrder)
  setActions.setColumnFilters(initialState.columnFilters)
  setActions.setColumnVisibility(initialState.columnVisibility)
  setActions.setDensity(initialState.density)
  setActions.setGlobalFilter(initialState.globalFilter)
  setActions.setShowGlobalFilter(initialState.showGlobalFilter)
  setActions.setShowColumnFilters(initialState.showColumnFilters)
  setActions.setSorting(initialState.sorting)
  setActions.setGrouping(initialState.grouping)
  setActions.setPagination(initialState.pagination)
  setActions.setExpanded(initialState.expanded)
  setActions.setColumnPinning(initialState.columnPinning)

  if (afterReset) {
    afterReset()
  }
}
