import { MRT_ColumnDef } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import React, { useMemo } from 'react'
import { getFailureStateBackgroundColor } from '../../../utils/getFailureStateColor'
import { sortingFailureState } from '../../sortFunctions/sortingFailureState'
import { Box } from '@mui/material'

const notAvailable = ''

export const highestStateColumnId = 'highestState'

export const useHighestStateColumnHeader = (
  data: FailuresListEntry[]
): MRT_ColumnDef<FailuresListEntry> => {
  const selectOptions = useMemo<string[]>(() => {
    const highestStates = data.map((entry) =>
      entry.highestState ? entry.highestState! : notAvailable
    )
    const distinctCurrentStates = highestStates.filter(
      (item, index) => highestStates.indexOf(item) === index
    )

    return distinctCurrentStates.sort((a, b) => (a > b ? 1 : -1))
  }, [data])

  return {
    id: highestStateColumnId,
    header: 'Highest State',
    accessorKey: 'highestState',
    enableColumnDragging: false,
    enableColumnActions: false,
    enableColumnFilterModes: false,
    filterVariant: 'multi-select',
    filterSelectOptions: selectOptions,
    enableResizing: false,
    minSize: 80,
    maxSize: 80,
    aggregationFn: 'unique',
    sortingFn: sortingFailureState,
    Cell: (data) => {
      const value = data.cell.getValue<string>()

      if (!value) {
        return <div>{notAvailable}</div>
      }

      return (
        <Box
          className='failure-state-box'
          style={{
            backgroundColor: `${getFailureStateBackgroundColor(value)}`
          }}
        >
          {value.replaceAll('FAILURESTATE_', '')}
        </Box>
      )
    }
  }
}
