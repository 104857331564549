import React, { useEffect, useState } from 'react'
import {
  MRT_ColumnFiltersState,
  MRT_ColumnOrderState,
  MRT_ColumnPinningState,
  MRT_DensityState,
  MRT_ExpandedState,
  MRT_GroupingState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_VisibilityState
} from 'material-react-table'
import { loadStateFromLocalStorage } from './loadStateFromLocalStorage'
import { saveStatesToLocalStorage } from './saveStatesToLocalStorage'
import {
  columnFiltersKey,
  columnOrderKey,
  columnPinningKey,
  columnVisibilityKey,
  densityKey,
  expandedKey,
  globalFilterKey,
  groupingKey,
  paginationKey,
  showColumnFiltersKey,
  showGlobalFilterKey,
  hideSpecificTagsKey,
  sortingKey
} from './localStorageKeys'
import { resetStateFromLocalStorage } from './resetStateFromLocalStorage'
import { getInitialState } from './initialState/getInitialState'

interface IFailureListState {
  resetState: () => void
  columnOrder: MRT_ColumnOrderState
  setColumnOrder: React.Dispatch<React.SetStateAction<MRT_ColumnOrderState>>
  columnFilters: MRT_ColumnFiltersState
  setColumnFilters: React.Dispatch<React.SetStateAction<MRT_ColumnFiltersState>>
  columnVisibility: MRT_VisibilityState
  setColumnVisibility: React.Dispatch<React.SetStateAction<MRT_VisibilityState>>
  density: MRT_DensityState
  setDensity: React.Dispatch<React.SetStateAction<MRT_DensityState>>
  globalFilter: string | undefined
  setGlobalFilter: React.Dispatch<React.SetStateAction<string | undefined>>
  showGlobalFilter: boolean
  setShowGlobalFilter: React.Dispatch<React.SetStateAction<boolean>>
  showColumnFilters: boolean
  setShowColumnFilters: React.Dispatch<React.SetStateAction<boolean>>
  sorting: MRT_SortingState
  setSorting: React.Dispatch<React.SetStateAction<MRT_SortingState>>
  grouping: MRT_GroupingState
  setGrouping: React.Dispatch<React.SetStateAction<MRT_GroupingState>>
  pagination: MRT_PaginationState
  setPagination: React.Dispatch<React.SetStateAction<MRT_PaginationState>>
  expanded: MRT_ExpandedState
  setExpanded: React.Dispatch<React.SetStateAction<MRT_ExpandedState>>
  columnPinning: MRT_ColumnPinningState
  setColumnPinning: React.Dispatch<React.SetStateAction<MRT_ColumnPinningState>>
  hideSpecificTags: boolean
  setHideSpecificTags: React.Dispatch<React.SetStateAction<boolean>>
}

export const useFailureListState = (): IFailureListState => {
  const initialState = getInitialState()

  // Table state
  const [isTableInitialized, setIsTableInitialized] = useState<boolean>(false)
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>(
    initialState.columnOrder
  )
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    initialState.columnFilters
  )
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    initialState.columnVisibility
  )
  const [density, setDensity] = useState<MRT_DensityState>(initialState.density)
  const [globalFilter, setGlobalFilter] = useState<string | undefined>(
    initialState.globalFilter
  )
  const [showGlobalFilter, setShowGlobalFilter] = useState<boolean>(
    initialState.showGlobalFilter
  )
  const [showColumnFilters, setShowColumnFilters] = useState<boolean>(
    initialState.showColumnFilters
  )
  const [sorting, setSorting] = useState<MRT_SortingState>(initialState.sorting)
  const [grouping, setGrouping] = useState<MRT_GroupingState>(
    initialState.grouping
  )
  const [pagination, setPagination] = useState<MRT_PaginationState>(
    initialState.pagination
  )
  const [expanded, setExpanded] = useState<MRT_ExpandedState>(
    initialState.expanded
  )
  const [columnPinning, setColumnPinning] = useState<MRT_ColumnPinningState>(
    initialState.columnPinning
  )
  const [hideSpecificTags, setHideSpecificTags] = useState<boolean>(
    initialState.hideSpecificTags
  )

  // Load state from local storage on mount
  useEffect(() => {
    loadStateFromLocalStorage({
      setColumnOrder,
      setColumnFilters,
      setColumnVisibility,
      setDensity,
      setGlobalFilter,
      setShowGlobalFilter,
      setShowColumnFilters,
      setSorting,
      setGrouping,
      setPagination,
      setExpanded,
      setColumnPinning,
      setHideSpecificTags
    })
    setIsTableInitialized(true)
  }, [])

  // Save state to local storage on any change after initialization
  useEffect(() => {
    if (!isTableInitialized) return
    const stateMap = {
      [columnOrderKey]: columnOrder,
      [columnFiltersKey]: columnFilters,
      [columnVisibilityKey]: columnVisibility,
      [densityKey]: density,
      [globalFilterKey]: globalFilter ?? '',
      [showGlobalFilterKey]: showGlobalFilter,
      [showColumnFiltersKey]: showColumnFilters,
      [sortingKey]: sorting,
      [groupingKey]: grouping,
      [paginationKey]: pagination,
      [expandedKey]: expanded,
      [columnPinningKey]: columnPinning,
      [hideSpecificTagsKey]: hideSpecificTags
    }

    Object.entries(stateMap).forEach(([key, value]) => {
      saveStatesToLocalStorage(key, value, isTableInitialized)
    })
  }, [
    columnOrder,
    columnFilters,
    columnVisibility,
    density,
    globalFilter,
    showGlobalFilter,
    showColumnFilters,
    sorting,
    grouping,
    pagination,
    expanded,
    columnPinning,
    hideSpecificTags,
    isTableInitialized
  ])

  const resetState = (afterReset?: () => void) => {
    resetStateFromLocalStorage(
      {
        setColumnOrder,
        setColumnFilters,
        setColumnVisibility,
        setDensity,
        setGlobalFilter,
        setShowGlobalFilter,
        setShowColumnFilters,
        setSorting,
        setGrouping,
        setPagination,
        setExpanded,
        setColumnPinning,
        setHideSpecificTags
      },
      afterReset
    )
  }

  return {
    resetState,
    columnOrder,
    setColumnOrder,
    columnFilters,
    setColumnFilters,
    columnVisibility,
    setColumnVisibility,
    density,
    setDensity,
    globalFilter,
    setGlobalFilter,
    showGlobalFilter,
    setShowGlobalFilter,
    showColumnFilters,
    setShowColumnFilters,
    sorting,
    setSorting,
    grouping,
    setGrouping,
    pagination,
    setPagination,
    expanded,
    setExpanded,
    columnPinning,
    setColumnPinning,
    hideSpecificTags,
    setHideSpecificTags
  }
}
