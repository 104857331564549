import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { Box } from '@mui/material'
import { FailuresListEntry } from '../../models/failuresListEntry'
import React, { useMemo } from 'react'
import { formatDateToUtcString } from '../../../utils/formatDateString'

export const isMutedColumnId = 'isMuted'

export const useMutedColumnHeader = (): MRT_ColumnDef<FailuresListEntry> => {
  const selectOptions = useMemo<string[]>(() => ['YES', 'NO'], [])

  return {
    id: isMutedColumnId,
    header: 'Muted',
    accessorKey: 'isMuted',
    visibleInShowHideMenu: false,
    enableColumnFilter: true,
    enableGrouping: false,
    enableSorting: false,
    enableHiding: false,
    enablePinning: false,
    enableResizing: false,
    enableMultiSort: false,
    enableColumnOrdering: false,
    enableEditing: false,
    enableColumnDragging: false,
    enableColumnActions: false,
    enableGlobalFilter: true,
    enableColumnFilterModes: false,
    minSize: 80,
    maxSize: 80,
    filterVariant: 'select',
    filterSelectOptions: selectOptions,
    filterFn: (row, id, filterValue) => {
      const isMuted = row.original.mutedUntil !== undefined
      return (
        (filterValue === 'YES' && isMuted) || (filterValue === 'NO' && !isMuted)
      )
    },
    accessorFn: (row: FailuresListEntry) => {
      const mutedUntil =
        row.mutedUntil &&
        formatDateToUtcString(new Date(row.mutedUntil), {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
      return mutedUntil || ''
    },
    Cell: ({ row }: { row: MRT_Row<FailuresListEntry> }) => {
      const isMuted = row.original.mutedUntil !== undefined

      const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
      }

      if (!isMuted) {
        return <Box sx={containerStyle}>-</Box>
      }

      const mutedUntil =
        row.original.mutedUntil &&
        formatDateToUtcString(new Date(row.original.mutedUntil), {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })

      return <Box sx={containerStyle}>{`Until ${mutedUntil}`}</Box>
    }
  }
}
