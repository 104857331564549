export interface FailuresListEntry {
  countryCode: string
  organizationName: string
  failureType: string
  currentState: string
  highestState?: string
  counterSinceLastAcknowledgement: number
  firstOccurrence: Date
  lastOccurrence: Date
  pumpName: string
  pumpId: string
  location: FailureListLocation
  assignee?: string
  isAcknowledged: boolean
  mutedUntil?: string | Date
  externalLinks?: FailureListExternalLink[]
  guardSensorId?: string
  tags: FailureListTag[]
}

export interface FailureListExternalLink {
  label: string
  url: string
}

export enum FailureListTagType {
  user = 'TAG',
  guard = 'GUARDTAG'
}

export interface FailureListTag {
  value: string
  type: FailureListTagType
}

export interface FailureListLocation {
  location: string
  technicalLocation: string
}
