export const saveStatesToLocalStorage = (
  key: string,
  value: any,
  isTableInitialized: boolean
) => {
  if (!isTableInitialized) {
    return
  }
  localStorage.setItem(key, JSON.stringify(value))
}
