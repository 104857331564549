export function generateLinkToMDC(
  organizationId: string,
  pumpId: string,
  firstOccurrence?: string,
  lastOccurrence?: string,
  currentUrl: string = window.location.href,
  currentDate: Date = new Date()
): string {
  const startDate = firstOccurrence || getDefaultStartDate(currentDate)
  const endDate = lastOccurrence || getDefaultEndDate(currentDate)

  return `${getMDCBaseUrl(currentUrl)}/?o=${organizationId}&p=${pumpId}&from=${buildDate(startDate)}&to=${buildDate(endDate)}&vfrom=${buildVDate(startDate)}&vto=${buildVDate(endDate)}`
}

function getMDCBaseUrl(currentUrl: string): string {
  if (currentUrl.includes('://mdcim.')) {
    return 'https://mdc.cloud.ksb.com'
  }

  if (currentUrl.includes('://mdcim-qal.')) {
    return 'https://mdc-qal.cloud.ksb.com'
  }

  if (currentUrl.includes('://mdcim-tst.')) {
    return 'https://mdc-tst.cloud.ksb.com'
  }

  if (!currentUrl.includes('mdcim') && !currentUrl.includes('localhost')) {
    throw new Error('Unexpected currentUrl given')
  }

  return 'https://mdc-dev.cloud.ksb.com'
}

function getDefaultStartDate(currentDate: Date): string {
  const date = new Date(currentDate)
  date.setUTCDate(date.getUTCDate() - 7)
  date.setUTCHours(0, 0, 0, 0)

  return date.toISOString()
}

function getDefaultEndDate(currentDate: Date): string {
  const date = new Date(currentDate)
  date.setUTCHours(23, 59, 59, 999)

  return date.toISOString()
}

function buildDate(inputDate: string): string {
  const trimmedDate = inputDate.replace('Z', '')

  return encodeURIComponent(trimmedDate)
}

function buildVDate(date: string): string {
  const trimmedDate = date.replace(/\.\d{3}Z$/, '')
  const encodedDate = encodeURIComponent(trimmedDate)

  return encodedDate.replace('T', '+')
}
