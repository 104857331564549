import { MRT_ColumnDef } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import { useMemo } from 'react'

export const countryCodeColumnId = 'countryCode'

export const useCountryCodeColumnHeader = (
  data: FailuresListEntry[]
): MRT_ColumnDef<FailuresListEntry> => {
  const selectOptions = useMemo<string[]>(() => {
    const countryCodes = data.map((entry) => entry.countryCode)
    const distinctCountryCodes = countryCodes.filter(
      (item, index) => countryCodes.indexOf(item) === index
    )
    return distinctCountryCodes.sort((a, b) => (a > b ? 1 : -1))
  }, [data])

  return {
    id: countryCodeColumnId,
    header: 'Country Code',
    accessorKey: 'countryCode',
    enableColumnDragging: false,
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableResizing: true,
    enableSorting: true,
    filterVariant: 'multi-select',
    filterSelectOptions: selectOptions,
    minSize: 50,
    maxSize: 100
  }
}
