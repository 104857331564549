import { MRT_ColumnDef } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'

export const guardSensorIdColumnId = 'guardSensorId'

export const useGuardSensorIdColumnHeader =
  (): MRT_ColumnDef<FailuresListEntry> => {
    return {
      id: guardSensorIdColumnId,
      header: 'Guard Sensor Id',
      accessorKey: 'guardSensorId',
      enableGlobalFilter: true,
      visibleInShowHideMenu: false
    }
  }
