import React, { useEffect, useMemo } from 'react'
import './failuresList.sass'
import {
  MaterialReactTable,
  MRT_TableInstance,
  useMaterialReactTable
} from 'material-react-table'
import { FailuresListEntry } from './models/failuresListEntry'
import { Stack, Switch, Tooltip, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { Restore, Sync } from '@mui/icons-material'
import { useFailureListState } from './state/useFailureListState'
import { useFailureListColumns } from './columnHeaders/useFailureListColumns'

interface IFailuresListProps {
  data: FailuresListEntry[]
  refreshAction: () => Promise<void>
  isLoading: boolean
}

const tagsToIgnore = ['TEST / DEMO', 'SENSOR DEFECT']

export const FailuresList: React.FC<IFailuresListProps> = (props) => {
  useEffect(() => {
    document.title = `IM | Issues`
  }, [])

  const {
    resetState,
    columnOrder,
    setColumnOrder,
    columnFilters,
    setColumnFilters,
    columnVisibility,
    setColumnVisibility,
    density,
    setDensity,
    globalFilter,
    setGlobalFilter,
    showGlobalFilter,
    setShowGlobalFilter,
    showColumnFilters,
    setShowColumnFilters,
    sorting,
    setSorting,
    grouping,
    setGrouping,
    pagination,
    setPagination,
    expanded,
    setExpanded,
    columnPinning,
    setColumnPinning,
    hideSpecificTags,
    setHideSpecificTags
  } = useFailureListState()

  const filteredData = useMemo<FailuresListEntry[]>(() => {
    if (!hideSpecificTags) {
      return props.data
    }

    return props.data.filter((entry) => {
      return !entry.tags.some((tag) =>
        tagsToIgnore.some((tagToIgnore) =>
          tag.value.toUpperCase().includes(tagToIgnore.toUpperCase())
        )
      )
    })
  }, [props.data, hideSpecificTags])

  const columns = useFailureListColumns(filteredData)

  const table: MRT_TableInstance<FailuresListEntry> = useMaterialReactTable({
    columns: columns,
    data: filteredData,
    enableRowSelection: false,
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableExpanding: false,
    enableRowPinning: false,
    enableGrouping: false,
    enableDensityToggle: false,
    enableColumnPinning: false,
    enableColumnFilterModes: false,
    enableSortingRemoval: true,
    enableHiding: false,
    enableColumnResizing: true,
    enableFilterMatchHighlighting: false,
    enableFullScreenToggle: false,
    columnFilterDisplayMode: 'popover',
    positionGlobalFilter: 'right',
    layoutMode: 'grid',
    onColumnOrderChange: setColumnOrder,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onDensityChange: setDensity,
    onGlobalFilterChange: setGlobalFilter,
    onShowColumnFiltersChange: setShowColumnFilters,
    onShowGlobalFilterChange: setShowGlobalFilter,
    onSortingChange: setSorting,
    onGroupingChange: setGrouping,
    onPaginationChange: setPagination,
    onExpandedChange: setExpanded,
    onColumnPinningChange: setColumnPinning,
    state: {
      columnOrder,
      columnFilters,
      columnVisibility,
      density,
      globalFilter,
      showGlobalFilter,
      showColumnFilters,
      sorting,
      grouping,
      pagination,
      expanded,
      columnPinning,
      showProgressBars: props.isLoading
    },
    muiTableBodyRowProps: ({ row }) => ({
      onDoubleClick: (e) => {
        const caseId = row.original.pumpId
        const caseDetailUrl = `/cases/${caseId}`

        if (e.ctrlKey || e.metaKey) {
          window.open(caseDetailUrl, '_blank')
        } else {
          window.location.assign(caseDetailUrl)
        }
      }
    }),
    muiFilterTextFieldProps: {
      sx: { m: '0', p: '0', width: '100%' },
      variant: 'outlined'
    },
    renderTopToolbarCustomActions: () => (
      <Stack direction='row' spacing={1}>
        <Tooltip arrow title='Refresh Data'>
          <IconButton onClick={() => props.refreshAction()}>
            <Sync />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title='Reset Table Configuration'>
          <IconButton
            onClick={() => resetState()}
            data-testid='failure-table-reset-config-button'
          >
            <Restore />
          </IconButton>
        </Tooltip>
        <Tooltip
          arrow
          title={
            <Stack direction={'column'}>
              <div>Hide following tags</div>

              {tagsToIgnore.map((tagToIgnore, index) => (
                <div key={`${tagToIgnore}-${index}`}>{tagToIgnore}</div>
              ))}
            </Stack>
          }
        >
          <Stack
            direction='row'
            spacing={0}
            data-testid='hide-specific-tags-switch'
          >
            <Switch
              checked={hideSpecificTags}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean
              ) => {
                setHideSpecificTags(checked)
              }}
            />
            <Typography style={{ marginTop: '6px' }}>
              Hide specific tags
            </Typography>
          </Stack>
        </Tooltip>
      </Stack>
    )
  })

  return (
    <div data-testid='failures-list-table'>
      <MaterialReactTable table={table} />
    </div>
  )
}
