import {
  columnFiltersKey,
  columnOrderKey,
  columnPinningKey,
  columnVisibilityKey,
  densityKey,
  expandedKey,
  globalFilterKey,
  groupingKey,
  paginationKey,
  showColumnFiltersKey,
  showGlobalFilterKey,
  hideSpecificTagsKey,
  sortingKey
} from './localStorageKeys'
import { ISetFailuresStateActions } from './ISetFailuresStateActions'

export const loadStateFromLocalStorage = (
  setActions: ISetFailuresStateActions
) => {
  const columnOrder = localStorage.getItem(columnOrderKey)
  const columnFilters = localStorage.getItem(columnFiltersKey)
  const columnVisibility = localStorage.getItem(columnVisibilityKey)
  const density = localStorage.getItem(densityKey)
  const globalFilter = localStorage.getItem(globalFilterKey)
  const showGlobalFilter = localStorage.getItem(showGlobalFilterKey)
  const showColumnFilters = localStorage.getItem(showColumnFiltersKey)
  const sorting = localStorage.getItem(sortingKey)
  const grouping = localStorage.getItem(groupingKey)
  const pagination = localStorage.getItem(paginationKey)
  const expanded = localStorage.getItem(expandedKey)
  const columnPinning = localStorage.getItem(columnPinningKey)
  const hideSpecificTags = localStorage.getItem(hideSpecificTagsKey)

  if (columnOrder) {
    setActions.setColumnOrder(JSON.parse(columnOrder))
  }
  if (columnFilters) {
    setActions.setColumnFilters(JSON.parse(columnFilters))
  }
  if (columnVisibility) {
    setActions.setColumnVisibility(JSON.parse(columnVisibility))
  }
  if (density) {
    setActions.setDensity(JSON.parse(density))
  }
  if (globalFilter) {
    setActions.setGlobalFilter(JSON.parse(globalFilter) || undefined)
  }
  if (showGlobalFilter) {
    setActions.setShowGlobalFilter(JSON.parse(showGlobalFilter))
  }
  if (showColumnFilters) {
    setActions.setShowColumnFilters(JSON.parse(showColumnFilters))
  }
  if (sorting) {
    setActions.setSorting(JSON.parse(sorting))
  }
  if (grouping) {
    setActions.setGrouping(JSON.parse(grouping))
  }
  if (pagination) {
    setActions.setPagination(JSON.parse(pagination))
  }
  if (expanded) {
    setActions.setExpanded(JSON.parse(expanded))
  }
  if (columnPinning) {
    setActions.setColumnPinning(JSON.parse(columnPinning))
  }
  if (hideSpecificTags) {
    setActions.setHideSpecificTags(JSON.parse(hideSpecificTags))
  }
}
