const tableName = 'FailuresTable'
export const columnOrderKey = tableName + 'ColumnOrderKey'
export const columnFiltersKey = tableName + 'ColumnFiltersKey'
export const columnVisibilityKey = tableName + 'ColumnVisibilityKey'
export const densityKey = tableName + 'DensityKey'
export const globalFilterKey = tableName + 'GlobalFilterKey'
export const showGlobalFilterKey = tableName + 'ShowGlobalFilterKey'
export const showColumnFiltersKey = tableName + 'ShowColumnFiltersKey'
export const sortingKey = tableName + 'SortingKey'
export const groupingKey = tableName + 'GroupingKey'
export const paginationKey = tableName + 'PaginationKey'
export const expandedKey = tableName + 'ExpandedKey'
export const columnPinningKey = tableName + 'ColumnPinningKey'
export const hideSpecificTagsKey = tableName + 'HideSpecificTagsKey'

export const localStorageKeys = [
  columnOrderKey,
  columnFiltersKey,
  columnVisibilityKey,
  densityKey,
  globalFilterKey,
  showGlobalFilterKey,
  showColumnFiltersKey,
  sortingKey,
  groupingKey,
  paginationKey,
  expandedKey,
  columnPinningKey,
  hideSpecificTagsKey
]
