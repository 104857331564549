import { useMemo } from 'react'
import { MRT_ColumnDef } from 'material-react-table'
import { FailuresListEntry } from '../models/failuresListEntry'
import { useCountryCodeColumnHeader } from './definitions/useCountryCodeColumnHeader'
import { useOrganizationNameColumnHeader } from './definitions/useOrganizationNameColumnHeader'
import { usePumpNameColumnHeader } from './definitions/usePumpNameColumnHeader'
import { useLocationColumnHeader } from './definitions/useLocationColumnHeader'
import { useFailureTypeColumnHeader } from './definitions/useFailureTypeColumnHeader'
import { useCurrentStateColumnHeader } from './definitions/useCurrentStateColumnHeader'
import { useHighestStateColumnHeader } from './definitions/useHighestStateColumnHeader'
import { useAssigneeColumnHeader } from './definitions/useAssigneeColumnHeader'
import { useFirstOccurrenceColumnHeader } from './definitions/useFirstOccurrenceColumnHeader'
import { useLastOccurrenceColumnHeader } from './definitions/useLastOccurrenceColumnHeader'
import { useTagsColumnHeader } from './definitions/useTagsColumnHeader'
import { useExternalLinkColumnHeader } from './definitions/useExternalLinkColumnHeader'
import { useMutedColumnHeader } from './definitions/useMutedColumnHeader'
import { useAcknowledgedColumnHeader } from './definitions/useAcknowledgedColumnHeader'
import { useGuardSensorIdColumnHeader } from './definitions/useGuardSensorIdColumnHeader'
import { usePumpIdColumnHeader } from './definitions/usePumpIdColumnHeader'

export const useFailureListColumns = (
  data: FailuresListEntry[]
): MRT_ColumnDef<FailuresListEntry>[] => {
  const countryCodeColumHeader = useCountryCodeColumnHeader(data)
  const organizationNameColumHeader = useOrganizationNameColumnHeader()
  const pumpNameColumnHeader = usePumpNameColumnHeader()
  const locationColumnHeader = useLocationColumnHeader()
  const failureTypeColumnHeader = useFailureTypeColumnHeader(data)
  const currentStateColumHeader = useCurrentStateColumnHeader(data)
  const highestStateColumHeader = useHighestStateColumnHeader(data)
  const assigneeColumHeader = useAssigneeColumnHeader(data)
  const firstOccurrenceColumHeader = useFirstOccurrenceColumnHeader()
  const lastOccurrenceColumHeader = useLastOccurrenceColumnHeader()
  const tagsColumHeader = useTagsColumnHeader(data)
  const externalLinkColumHeader = useExternalLinkColumnHeader()
  const mutedColumHeader = useMutedColumnHeader()
  const acknowledgedColumHeader = useAcknowledgedColumnHeader()
  const guardSensorIdColumnHeader = useGuardSensorIdColumnHeader()
  const pumpIdColumnHeader = usePumpIdColumnHeader()

  return useMemo<MRT_ColumnDef<FailuresListEntry>[]>(() => {
    return [
      countryCodeColumHeader,
      organizationNameColumHeader,
      pumpNameColumnHeader,
      locationColumnHeader,
      failureTypeColumnHeader,
      currentStateColumHeader,
      highestStateColumHeader,
      assigneeColumHeader,
      firstOccurrenceColumHeader,
      lastOccurrenceColumHeader,
      tagsColumHeader,
      externalLinkColumHeader,
      mutedColumHeader,
      acknowledgedColumHeader,
      // hidden columns
      guardSensorIdColumnHeader,
      pumpIdColumnHeader
    ]
  }, [
    acknowledgedColumHeader,
    assigneeColumHeader,
    countryCodeColumHeader,
    currentStateColumHeader,
    externalLinkColumHeader,
    failureTypeColumnHeader,
    firstOccurrenceColumHeader,
    guardSensorIdColumnHeader,
    highestStateColumHeader,
    lastOccurrenceColumHeader,
    locationColumnHeader,
    mutedColumHeader,
    organizationNameColumHeader,
    pumpIdColumnHeader,
    pumpNameColumnHeader,
    tagsColumHeader
  ])
}
